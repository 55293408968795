<template>
  <div class="activity-page">
    <main>
      <div class="activity-online-block" v-if="onlineList.length > 0">
        <div class="title">
          <p class="categoryname">摄影大赛</p>
          <div class="line"></div>
        </div>
        <div class="online-box">
          <div
            class="online-item-y"
            v-if="onlineList.length > 0"
            @click="handleDetail(onlineList[0].id, onlineList[0].type)"
          >
            <el-image
              class="grid-img"
              @contextmenu="handleMouse"
              :src="onlineList[0].ximage"
              fit="cover"
            ></el-image>
            <div class="activity-name">{{ onlineList[0].activity_name }}</div>
            <div class="activity-time">
              <p>参赛时间</p>
              {{ onlineList[0].start_time }}-{{ onlineList[0].end_time }}
            </div>
            <div class="activity-organizer">
              {{ onlineList[0].second_name }}
            </div>
            <div
              class="activity-type"
              :class="
                onlineList[0].activity_status == 4
                  ? 'blue'
                  : onlineList[0].activity_status == 5
                  ? 'yellow'
                  : ''
              "
            >
              {{ onlineList[0].activity_status_name }}
            </div>
          </div>
          <div class="online-item-x">
            <template v-for="(item, index) in onlineList">
              <div class="item" v-if="index >= 1" :key="index">
                <div @click="handleDetail(item.id, item.type)">
                  <div class="item-img">
                    <el-image
                      class="grid-img"
                      @contextmenu="handleMouse"
                      :src="item.yimage"
                      fit="cover"
                    ></el-image>
                    <div
                      class="activity-type"
                      :class="
                        item.activity_status == 4
                          ? 'blue'
                          : item.activity_status == 5
                          ? 'yellow'
                          : ''
                      "
                    >
                      {{ item.activity_status_name }}
                    </div>
                  </div>
                  <div class="item-text">
                    <div class="activity-name">{{ item.activity_name }}</div>
                    <div class="activity-time">
                      {{ item.start_time }} - {{ item.end_time }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="activity-offline-block" v-if="offlineList.length > 0">
        <div class="title">
          <p class="categoryname">线下展览</p>
          <div class="line"></div>
        </div>
        <div class="offline-box">
          <div
            class="offline-item-y"
            v-if="offlineList.length > 0"
            @click="handleDetail(offlineList[0].id, offlineList[0].type)"
          >
            <el-image
              class="grid-img"
              @contextmenu="handleMouse"
              :src="offlineList[0].ximage"
              fit="cover"
            ></el-image>
            <div class="activity-year">
              {{ offlineList[0].start_time.split("-")[0] }}
            </div>
            <div class="activity-name">{{ offlineList[0].activity_name }}</div>
            <div class="activity-time">
              <p>TIME 时间</p>
              {{ offlineList[0].start_time }}-{{ offlineList[0].end_time }}
            </div>
            <div class="activity-organizer">
              {{ offlineList[0].second_name }}
            </div>
          </div>
          <div class="offline-item-x">
            <template v-for="(item, index) in offlineList">
              <div class="item" v-if="index >= 1" :key="index">
                <div
                  v-if="index >= 1"
                  @click="handleDetail(item.id, item.type)"
                >
                  <div class="item-img">
                    <el-image
                      class="grid-img"
                      @contextmenu="handleMouse"
                      :src="item.yimage"
                      fit="cover"
                    ></el-image>
                  </div>
                  <div class="item-text">
                    <div class="activity-year">
                      {{ item.start_time.split("-")[0] }}
                    </div>
                    <div class="activity-organizer">
                      {{ item.second_name }}
                    </div>
                    <div class="activity-name">{{ item.activity_name }}</div>
                    <!-- <div class="activity-title">
                      <span>{{ item.second_name }}</span>
                    </div> -->
                    <div class="activity-time">
                      {{ item.host_city || item.address }} |
                      {{ item.start_time }}-{{ item.end_time }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="no-data"
        v-if="onlineList.length <= 0 && offlineList.length <= 0"
      >
        暂无活动，敬请期待～
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      onlineList: [], //线上活动
      offlineList: [] //线下活动
    };
  },
  async mounted() {
    const { data } = await this.$api.getActivityList();
    if (data.code === 1) {
      this.onlineList = data.data.online;
      this.offlineList = data.data.offline;
    }
  },
  methods: {
    handleDetail(id, type) {
      this.$router.push({ name: "activityDetail", query: { id, type } });
    },
    handleMouse(e) {
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.activity-page {
  padding: 0 0 50px;
  margin: 0 auto;
  color: #000000;
  min-height: 100%;
  .activity-online-block {
    padding-top: 107px;
    .title {
      text-align: left;
      width: 118px;
      margin-left: 16px;
      margin-bottom: 58px;
      .categoryname {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 8px;
      }
      .line {
        width: 30px;
        height: 6px;
        background: #e60021;
        border-radius: 6px;
        margin-top: 10px;
        position: relative;
        // display: none;
        &:after {
          content: " ";
          position: absolute;
          right: -8px;
          top: 0;
          width: 6px;
          height: 6px;
          background: #e60021;
          border-radius: 6px;
        }
      }
    }
    .online-box {
      padding-bottom: 50px;
      .online-item-y {
        width: 100%;
        margin-bottom: 110px;
        position: relative;
        cursor: pointer;
        .grid-img {
          width: 100%;
          height: 556px;
          border-radius: 7px;
          cursor: pointer;
        }
        .activity-name {
          position: absolute;
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          bottom: 196px;
          left: 46px;
        }
        .activity-time {
          position: absolute;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          bottom: 106px;
          left: 43px;
          p {
            padding-bottom: 6px;
          }
        }
        .activity-organizer {
          position: absolute;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          bottom: 39px;
          left: 45px;
        }
        .activity-type {
          position: absolute;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          right: 15px;
          bottom: 20px;
          width: 91px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: #70ab95;
          border-radius: 15px;
          color: #ffffff;
        }
        .blue {
          background: #96a6cd;
        }
        .yellow {
          background: #f2d587;
        }
      }
      .online-item-x {
        overflow: hidden;
        .item {
          width: 33.33%;
          float: left;
          padding-bottom: 50px;
          cursor: pointer;
          &:nth-child(3n + 1) {
            padding-right: 37px;
          }
          &:nth-child(3n + 2) {
            padding: 0 18px;
          }
          &:nth-child(3n + 3) {
            padding-left: 37px;
          }
          .item-img {
            position: relative;
            .grid-img {
              width: 100%;
              height: 533px;
              border-radius: 7px;
              cursor: pointer;
            }
            .activity-type {
              position: absolute;
              font-size: 17px;
              right: 15px;
              bottom: 20px;
              width: 91px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              background: #70ab95;
              border-radius: 15px;
              color: #ffffff;
            }
            .blue {
              background: #96a6cd;
            }
            .yellow {
              background: #f2d587;
            }
          }
          .item-text {
            // padding: 10px 0;
            .activity-name {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #000000;
              padding: 37px 0 20px;
            }
            .activity-time {
              font-size: 14px;
              color: #b5b5b6;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .activity-offline-block {
    padding-top: 60px;
    .title {
      text-align: left;
      width: 118px;
      margin-left: 16px;
      margin-bottom: 58px;
      .categoryname {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 8px;
      }
      .line {
        width: 30px;
        height: 6px;
        background: #e60021;
        border-radius: 6px;
        margin-top: 10px;
        position: relative;
        // display: none;
        &:after {
          content: " ";
          position: absolute;
          right: -8px;
          top: 0;
          width: 6px;
          height: 6px;
          background: #e60021;
          border-radius: 6px;
        }
      }
    }
    .offline-box {
      padding-bottom: 50px;
      .offline-item-y {
        width: 100%;
        margin-bottom: 110px;
        position: relative;
        cursor: pointer;
        .grid-img {
          width: 100%;
          height: 556px;
          border-radius: 7px;
          cursor: pointer;
        }
        .activity-year {
          position: absolute;
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #000000;
          line-height: 26px;
          bottom: 333px;
          left: 46px;
        }
        .activity-name {
          position: absolute;
          font-size: 45px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 26px;
          bottom: 209px;
          left: 46px;
        }
        .activity-time {
          position: absolute;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          bottom: 55px;
          left: 43px;
          p {
            padding-bottom: 6px;
          }
        }
        .activity-organizer {
          position: absolute;
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #000000;
          bottom: 270px;
          left: 46px;
        }
      }
      .offline-item-x {
        overflow: hidden;
        .item {
          width: 33.33%;
          float: left;
          padding-bottom: 50px;
          cursor: pointer;
          &:nth-child(3n + 1) {
            padding-right: 37px;
          }
          &:nth-child(3n + 2) {
            padding: 0 18px;
          }
          &:nth-child(3n + 3) {
            padding-left: 37px;
          }
          .item-img {
            position: relative;
            .grid-img {
              width: 100%;
              height: 533px;
              border-radius: 7px;
              cursor: pointer;
            }
          }
          .item-text {
            .activity-year {
              font-size: 20px;
              font-family: PingFangSC-Regular;
              // font-weight: 400;
              color: #000000;
              padding: 37px 0 0px;
            }
            .activity-name {
              font-size: 20px;
              font-family: PingFangSC-Regular;
              color: #000000;
              padding: 10px 0 30px;
            }
            .activity-organizer {
              font-size: 20px;
              font-family: PingFangSC-Light;
              font-weight: 400;
              color: #000000;
              padding: 10px 0 0;
            }
            .activity-title {
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #000000;
            }
            .activity-time {
              font-size: 20px;
              font-family: PingFangSC-Light;
              font-weight: 100;
              color: #666464;
            }
          }
        }
      }
    }
  }
  .no-data {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #000000;
    text-align: center;
    margin: 160px auto;
  }
}
//// 媒体查询 小于等于某个宽度
@media screen and (max-width: 1339px) {
  .activity-page {
    width: 1060px;
  }
}
// 媒体查询 大于等于某个宽度
@media screen and (min-width: 1080px) {
  .activity-page {
    width: 1060px;
  }
}
@media screen and (min-width: 1340px) {
  .activity-page {
    width: 1200px;
  }
}
@media screen and (min-width: 1440px) {
  .activity-page {
    width: 1312px;
  }
}
@media screen and (min-width: 1921px) {
  .activity-page {
    width: 1312px;
  }
}
</style>
